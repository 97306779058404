import Vue from 'vue'

import ChatApp from '../ChatApp.vue'
import Config from '@services/Config'
import { htmlDecode } from '@utils'

// const meData = {
//   "id": 4492836,
//   "groupId": 30942,
//   "firstName": "User",
//   "lastName": Math.floor(Math.random() * 100) + 1,
//   "company": "Webb Integrated",
//   "title": "Developer",
//   "country": "US",
//   "primaryEmail": "tyler@webbintegrated.com",
//   "primaryEmailValid": true,
//   "status": "user",
//   "emails": [
//     "tyler@webbintegrated.com"
//   ],
//   "isJoined": false
// }

function harvestContextData() {
  var data = {}
  var meTag = document.getElementById('bootstrap-data-me')
  if (meTag) {
    data.me = JSON.parse(htmlDecode(meTag.innerHTML))
  }

  var eventTag = document.getElementById('bootstrap-data-event')
  if (eventTag) {
    data.event = JSON.parse(htmlDecode(eventTag.innerHTML))
  }
  
  if (location.hostname === 'events.bizzabo.com') {
    var groupId = data.event.groupId
    var r = new RegExp(`x-bz-access-attendee-token-${groupId}=([A-z0-9]{8}-[A-z0-9]{4}-[A-z0-9]{4}-[A-z0-9]{4}-[A-z0-9]{12})`)
  } else {
    var r = /x-bz-access-attendee-token=([A-z0-9]{8}-[A-z0-9]{4}-[A-z0-9]{4}-[A-z0-9]{4}-[A-z0-9]{12})/
  }

  var cookieMatch = document.cookie.match(r)
  if (cookieMatch && cookieMatch.length === 2) {
    data.accessToken = cookieMatch[1]
  }
  
  return data
}


window.initChat = function (opts = {}) {
  Config.set('base-url', opts.baseUrl)
  Config.set('d3g-app-key', opts.appKey)
  Config.set('logo-png-url', opts.logoPngUrl)
  Config.set('pubnub-publish-key', opts.pubnubPublishKey)
  Config.set('pubnub-subscribe-key', opts.pubnubSubscribeKey)

  if (document.getElementById('chat-app')) {
    var ctx = harvestContextData()
    if (ctx.me && ctx.event && ctx.accessToken) {
      Config.set('me', ctx.me)
      Config.set('event-id', ctx.event.id)
      Config.set('bz-auth-token', ctx.accessToken)
  
      new Vue({
        el: '#chat-app',
        render: h => h(ChatApp),
      })
    }
  }
}
